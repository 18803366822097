import React, { Component } from "react";

import logo from "../../assets/vlogo_187x175.png";
import { Redirect } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";

// styling
const custom_style = {
  navbar: {
    backgroundColor: "#383838",
  },

  logo: {
    marginBottom: "20px",
  },

  regular: {
    width: "100",
    fontWeight: "900",
    fontSize: "12px",
    marginRight: "50px",
    color: "white",
    backgroundColor: "#da291c",
  },

  last: {
    width: "100",
    fontWeight: "900",
    fontSize: "12px",
    color: "white",
    backgroundColor: "#da291c",
  },

  navbarCollapse: {
    marginBottom: "10px",
  },
};

class NavigationItems extends Component {
  state = {
    navlinks: {
      first: {
        name: "Retrieve Application",
        link: "/",
        type: "normal",
      },

      mid: {
        name: "Admin Panel",
        link: "/admin",
        type: "normal",
      },

      last: {
        name: "About the Fellowship",
        link: "https://aisingapore.org/research/phd-fellowship-programme/",
        type: "normal",
      },

      logout: {
        name: "Logout",
        link: "/admin",
        type: "logout",
      },
    },

    redirect: false,
  };

  // handling logout
  onClickHandler = (event) => {
    event.preventDefault();
    localStorage.clear();
    this.setState({ redirect: true });
  };

  render() {
    /*
        let navlinkslength = Object.entries(this.props.links).length
        let navlinks = Object.entries(this.props.links).map(
            ([key, value], idx) => {
                return <Nav.Link 
                            key = {idx} 
                            style = {idx === navlinkslength - 1 ? custom_style.link2 : custom_style.link1} 
                            href={value.link} 
                            onClick={value.type === 'logout' ? this.onClickHandler : null}>{value.name}</Nav.Link>
            }
        )
        */

    let navlinks = (
      <>
        <Nav.Link
          style={custom_style.regular}
          href={this.state.navlinks.first.link}
        >
          {" "}
          {this.state.navlinks.first.name}
        </Nav.Link>

        <Nav.Link
          style={custom_style.regular}
          href={
            this.props.login
              ? this.state.navlinks.logout.link
              : this.state.navlinks.mid.link
          }
          onClick={this.props.login ? this.onClickHandler : null}
        >
          {" "}
          {this.props.login
            ? this.state.navlinks.logout.name
            : this.state.navlinks.mid.name}
        </Nav.Link>

        <Nav.Link
          style={custom_style.last}
          href={this.state.navlinks.last.link}
        >
          {" "}
          {this.state.navlinks.last.name}
        </Nav.Link>
      </>
    );

    if (!this.state.redirect) {
      return (
        <>
          <Navbar
            className="flex-column custom-navbar"
            style={custom_style.navbar}
          >
            <Navbar.Brand
              href="#home"
              className="mx-auto"
              style={custom_style.logo}
            >
              <img
                alt=""
                src={logo}
                width="140px"
                height="130px"
                className="d-inline-block align-center"
              />{" "}
            </Navbar.Brand>
            <Navbar.Collapse style={custom_style.navbarCollapse}>
              {navlinks}
            </Navbar.Collapse>
          </Navbar>
        </>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default NavigationItems;
