export const customStyle = {
  items: {
    position: "relative",
    marginLeft: "-33%",
    marginRight: "-33%",
    align: "center",
  },

  topBuffer30: {
    marginTop: "30px",
  },

  topBuffer20: {
    marginTop: "20px",
    //width: "1450px",
  },

  tabSpan: {
    marginTop: "25px",
    textAlign: "justify",
    width: "100%",
  },

  errorMessage: {
    marginTop: "10px",
    color: "red",
    fontWeight: "bold",
  },

  successMessage: {
    marginTop: "10px",
    color: "green",
    fontWeight: "bold",
  },

  center: {
    textAlign: "center",
  },

  subConfirm: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "orange",
  },

  subForeWord: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "blue",
  },

  subHeader: {
    fontSize: "14px",
    fontWeight: "bold",
  },

  subText: {
    fontSize: "12px",
    color: "blue",
    fontStyle: "italic",
  },
  subTextWarn: {
    fontSize: "12px",
    color: "red",
    fontStyle: "italic bold",
  },

  sectionBorderRed: {
    border: "2px solid red",
    padding: "15px",
  },

  bordered: {
    border: "1px solid black",
    paddingTop: "8px",
  },
  sectionBorderBlue: {
    border: "2px solid blue",
    padding: "15px",
  },

  sectionBorderNorm: {
    border: "1px solid black",
    borderRadius: "25px",
    padding: "10px",
  },

  sectionSubText: {
    fontSize: "18px",
    fontWeight: "bold",
  },

  tableCustom: {
    tableLayout: "fixed",
    width: "100%",
  },

  wordBreak: {
    width: "30%",
    wordWrap: "break-word",
  },

  wordBreakTwo: {
    width: "45%",
    wordWrap: "break-word",
  },

  scrollButton: {
    position: "fixed",
  },

  rejectedMsg: {
    color: "red",
    fontWeight: "bold",
  },

  inforMsg: {
    color: "blue",
    fontWeight: "bold",
  },

  acceptedMsg: {
    color: "green",
    fontWeight: "bold",
  },

  withdrawnMsg: {
    color: "purple",
    fontWeight: "bold",
  },
};

export const locale_string = (function () {
  if (navigator.languages !== undefined) {
    return navigator.languages[0];
  }
  return navigator.language;
})();

export const locale_options = {
  //dateStyle: "full",
  //timeStyle: "long",
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
};
