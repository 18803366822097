import React, { Component } from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";

//styling
import { customStyle } from "../../../hoc/CustomStyle";
import { FormMapper } from "../../../hoc/FormConfig";

class ApplicantView extends Component {
  state = {
    render: {
      mode: "loaded", //loading
      mounted: false,
    },

    data: null,

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  downloadHandler(app_id) {
    const FileDownload = require("js-file-download");

    let accessToken = localStorage.getItem("app_access");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "file/applicant/download/single",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        responseType: "arraybuffer",
      })
        .then((received) => {
          FileDownload(received.data, app_id + "_applicant_upload.zip");
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    }
  }

  render() {
    let headers = this.sortByOrder(this.props.sub_infor.header);

    let Components = Object.entries(headers).map(([key, element], idx) => {
      let title = element.name;
      let content = this.props.sub_infor.content[key];

      let render = null;

      if (element.type === "list") {
        let list_items = content.map((value, idx) => {
          return (
            <li key={idx}>{FormMapper[value] ? FormMapper[value] : value}</li>
          );
        });

        render = <ul>{list_items}</ul>;
      } else if (element.type === "table") {
        let ordered_headers = this.sortByOrder(element.sub_header);

        let table_content = content.map((data, idx) => {
          let rows = Object.entries(ordered_headers).map(
            ([row_key, row_value], idx) => {
              return (
                <>
                  <tr>
                    <th>{row_value.name}</th>
                    <td>
                      {FormMapper[data[row_key]]
                        ? FormMapper[data[row_key]]
                        : data[row_key]}
                    </td>
                  </tr>
                </>
              );
            }
          );

          return (
            <>
              <tr>
                <td colspan="2">
                  <b>
                    <i>Entry No.{idx + 1}</i>
                  </b>
                </td>
              </tr>
              {rows}
              <br></br>
            </>
          );
        });

        render = (
          <Table striped bordered responsive>
            {table_content}
          </Table>
        );
      } else if (element.type === "upload") {
        render = content ? (
          <Button
            variant="primary"
            onClick={() =>
              this.downloadHandler(this.props.sub_infor.content["app_id"])
            }
          >
            Download
          </Button>
        ) : (
          <p>N/A</p>
        );
      } else {
        render = FormMapper[content] ? FormMapper[content] : content;
      }

      return (
        <>
          <tr>
            <th>{title}</th>
            <td>{render}</td>
          </tr>
        </>
      );
    });

    let outcome_msg = null;
    if (this.props.sub_infor.outcome === "rejected") {
      outcome_msg = (
        <p style={customStyle.rejectedMsg}>
          {FormMapper[this.props.sub_infor.outcome]}
        </p>
      );
    } else if (this.props.sub_infor.outcome === "awarded") {
      outcome_msg = (
        <p style={customStyle.acceptedMsg}>
          {FormMapper[this.props.sub_infor.outcome]}
        </p>
      );
    } else {
      outcome_msg = (
        <p style={customStyle.inforMsg}>
          {FormMapper[this.props.sub_infor.outcome]}
        </p>
      );
    }

    return (
      <>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>
        <div style={customStyle.topBuffer20}>
          <Table striped bordered hover size="sm">
            <tbody>
              <tr>
                <th>Status</th>
                <td>{outcome_msg}</td>
              </tr>
              {Components}
            </tbody>
          </Table>
        </div>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>
      </>
    );
  }
}

export default ApplicantView;
