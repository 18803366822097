import React from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { customStyle } from "../../../hoc/CustomStyle";

const input = (props) => {
  let inputElement = null;

  let isValid = false;
  let isInvalid = false;

  if (props.shouldValidate && props.touched) {
    if (props.invalid) {
      isInvalid = true;
      isValid = false;
      props.feedback.status = "invalid";
    } else {
      isInvalid = false;
      isValid = true;
      props.feedback.status = "valid";
    }
  }

  switch (props.elementType) {
    case "input-normal":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              <b>{props.label.main}</b>
              <p style={customStyle.subText}>{props.label.sub}</p>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                {...props.elementConfig}
                value={props.value}
                style={props.style}
                onChange={props.changed}
                isValid={isValid}
                isInvalid={isInvalid}
                readOnly={props.noChange}
              />
              <Form.Control.Feedback type={props.feedback.status}>
                {props.feedback.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "input-textarea":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              <b>{props.label.main}</b>
              <p style={customStyle.subText}>{props.label.sub}</p>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                isValid={isValid}
                isInvalid={isInvalid}
              />
              <Form.Control.Feedback type={props.feedback.status}>
                {props.feedback.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "input-textbox":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <b>{props.label.main}</b>
            <p style={customStyle.subText}>{props.label.sub}</p>
            <Form.Control
              {...props.elementConfig}
              as="textarea"
              rows="5"
              value={props.value}
              onChange={props.changed}
              isValid={isValid}
              isInvalid={isInvalid}
            />
            <Form.Control.Feedback type={props.feedback.status}>
              {props.feedback.message}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      );
      break;
    case "input-select":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              <b>{props.label.main}</b>
              <p style={customStyle.subText}>{props.label.sub}</p>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="select"
                isValid={isValid}
                isInvalid={isInvalid}
                value={props.value}
                onChange={props.changed}
              >
                {props.elementConfig.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type={props.feedback.status}>
                {props.feedback.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "input-radio":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={11}>
              <b>{props.label.main}</b>
              <p style={customStyle.subText}>{props.label.sub}</p>
            </Form.Label>
            <Form
              isValid={isValid}
              isInvalid={isInvalid}
              value={props.value}
              onChange={props.changed}
              style={{ marginBottom: "20px", marginLeft: "15px" }}
            >
              {props.elementConfig.options.map((option) => (
                <Form.Check
                  name={props.label.main}
                  type="radio"
                  value={option.value}
                  label={option.label}
                  checked={props.value === option.value}
                />
              ))}
              <Form.Control.Feedback type={props.feedback.status}>
                {props.feedback.message}
              </Form.Control.Feedback>
            </Form>
          </Form.Group>
        </>
      );
      break;
    case "input-checkbox":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Col sm={10}>
              <Form.Check
                label={props.elementDecorators.label}
                {...props.elementConfig}
                inline={true}
              />
              <Form.Control.Feedback type={props.feedback.status}>
                {props.feedback.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;
    case "upload":
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              <b>{props.elementDecorators.label}</b>
              <p style={customStyle.subText}>
                {props.elementDecorators.sublabel}
              </p>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                {...props.elementConfig}
                onChange={props.changed}
                isValid={isValid}
                isInvalid={isInvalid}
              />
              <Form.Control.Feedback type={props.feedback.status}>
                {props.feedback.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      );
      break;

    default:
      inputElement = (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              <b>{props.label.main}</b>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                isValid={isValid}
                isInvalid={isInvalid}
              />
            </Col>
            <Form.Control.Feedback type={props.feedback.status}>
              {props.feedback.message}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      );
  }

  return <>{inputElement}</>;
};

export default input;
