import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Input from "../../../../components/UI/Input/Input";

import { checkValidity } from "../../../../hoc/Util";
import { customStyle } from "../../../../hoc/CustomStyle";
import axios from "axios";

class GrantForm extends Component {
  state = {
    controls: {
      /*
      status: {
        elementType: "input-select",
        elementConfig: {
          options: [
            {
              value: "",
              label: "<------ (select a status from drop-down) ------>",
            },
            { value: "open", label: "Open" },
            { value: "closed", label: "Closed" },
          ],
        },
        label: {
          main: "Status",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "looks good!",
        },
        value: this.props.grant_infor.content
          ? this.props.grant_infor.content.status
          : "",
        validation: {
          required: true,
        },
        valid: true,
        touched: this.props.grant_infor.content ? true : false,
      },
      */

      title: {
        elementType: "input-normal",
        elementConfig: {
          type: "text",
          placeholder: "(enter a title for the grant)",
        },
        label: {
          main: "Title",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.props.grant_infor.content
          ? this.props.grant_infor.content.title
          : "",
        valid: this.props.grant_infor.content ? true : false,
        touched: this.props.grant_infor.content ? true : false,
      },

      closing_date: {
        elementType: "input-normal",
        elementConfig: {
          type: "date",
          placeholder: "(format: dd-mm-yyyy)",
          required: true,
        },
        label: {
          main: "Endorsed Submission Closing Date",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.props.grant_infor.content
          ? this.props.grant_infor.content.closing_date
          : "",
        valid: this.props.grant_infor.content ? true : false,
        touched: this.props.grant_infor.content ? true : false,
      },

      closing_time: {
        elementType: "input-normal",
        elementConfig: {
          type: "time",
          placeholder: "(format: hh:mm)",
          required: true,
        },
        label: {
          main: "Time on Submission Closing Date (UTC+08:00)",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.props.grant_infor.content
          ? this.props.grant_infor.content.closing_time
          : "",
        valid: this.props.grant_infor.content ? true : false,
        touched: this.props.grant_infor.content ? true : false,
      },

      outcomes_date: {
        elementType: "input-normal",
        elementConfig: {
          type: "date",
          placeholder: "(format: dd-mm-yyyy)",
          required: true,
        },
        label: {
          main: "Outcome Emails Date",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.props.grant_infor.content
          ? this.props.grant_infor.content.outcomes_date
          : "",
        valid: this.props.grant_infor.content ? true : false,
        touched: this.props.grant_infor.content ? true : false,
      },

      outcomes_time: {
        elementType: "input-normal",
        elementConfig: {
          type: "time",
          placeholder: "(format: hh:mm)",
          required: true,
        },
        label: {
          main: "Time on Outcome Emails Date (UTC+08:00)",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.props.grant_infor.content
          ? this.props.grant_infor.content.outcomes_time
          : "",
        valid: this.props.grant_infor.content ? true : false,
        touched: this.props.grant_infor.content ? true : false,
      },
    },

    payload: {
      grant_id: this.props.grant_infor.retrieve_id
        ? this.props.grant_infor.retrieve_id
        : null,
    },

    loading: false,

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.externalMsg !== this.state.externalMsg) {
      this.props.parentHandler("submitted");
    }
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        feedback: {
          ...this.state.controls[controlName].feedback,
          message: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();

    let validated = true;
    let values = {};

    if (this.props.grant_infor.content) {
      values["grant_id"] = this.props.grant_infor.content.grant_id;
    }

    Object.entries(this.state.controls).forEach(([formKey, formEntry], idx) => {
      if (validated && !formEntry.valid) {
        validated = false;
        //console.log(formKey + " is invalid!");
      }
      values[formKey] = formEntry.value;
    });

    if (validated) {
      let accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "grantcalls/post",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: values["grant_id"] ? "update" : "create",
            received_data: values,
          },
        })
          .then((received) => {
            if (received.status === 200) {
              if (received.data.status) {
                this.setState({
                  ...this.state,
                  loading: false,
                  externalMsg: {
                    triggered: true,
                    type: "success",
                    content: received.data.message,
                  },
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: false,
                  internalMsg: {
                    triggered: true,
                    type: "error",
                    content: received.data.message,
                  },
                });
              }
            } else {
              this.setState({
                ...this.state,
                loading: false,
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "received state not 200",
                },
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.setState({
                ...this.state,
                loading: false,
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "critical error: " + error,
                },
              });
            }
          });
      }

      this.setState({
        ...this.state,
        loading: true,
      });
    }
  };

  render() {
    let msg = null;
    if (
      this.state.internalMsg.triggered &&
      this.state.internalMsg.type === "error"
    ) {
      msg = (
        <p style={customStyle.errorMessage}>{this.state.internalMsg.content}</p>
      );
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    console.log(formElementsArray);

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        invalid={!formElement.config.valid}
        feedback={formElement.config.feedback}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    return (
      <>
        {msg}
        <Button variant="info" onClick={() => this.props.parentHandler("back")}>
          Back
        </Button>
        <div style={customStyle.topBuffer20}>
          <Form onSubmit={this.submitHandler}>
            {form}
            <Button type="submit" disabled={this.state.loading}>
              Submit
            </Button>
          </Form>
        </div>
      </>
    );
  }
}

export default GrantForm;
