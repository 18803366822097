import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import UserCreate from "./UserCreate/UserCreate";
import UserForm from "./UserForm/UserForm";
import UserView from "./UserView/UserView";

import { FormMapper } from "../../../hoc/FormConfig";
import { customStyle } from "../../../hoc/CustomStyle";
import axios from "axios";

class Users extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "table", // table, form, create
      user_type: null,
    },

    table: {
      header: null,
      content: null,
      others: null,
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    view: {
      retrieve_id: null,
      header: null,
      content: null,
      status: null,
    },

    spinner: {
      toggle: false,
      msg: "",
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.formHandler = this.formHandler.bind(this);
  }

  toggleSpinner(trigger, message) {
    this.setState({
      ...this.state,
      spinner: {
        toggle: trigger,
        msg: message,
      },
    });
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  loadpageHandler(content_type, content_id) {
    this.toggleSpinner(true, "null");

    // load profile information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      let params = {
        request_type: content_type,
        retrieve_id: content_id,
      };

      if (params) {
        // table
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "fetch/users/" + content_type,
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: params.request_type,
            request_id: params.retrieve_id,
          },
        })
          .then((received) => {
            this.toggleSpinner(false, "null");

            if (received.status === 200) {
              if (received.data.status) {
                let updatedStates = {};
                if (params.request_type === "table") {
                  updatedStates = {
                    ...this.state,
                    table: {
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      others: received.data.payload.others,
                    },
                    form: {
                      retrieve_id: null,
                      header: null,
                      content: null,
                    },
                  };
                } else if (params.request_type === "form") {
                  updatedStates = {
                    ...this.state,
                    form: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    table: {
                      header: null,
                      content: null,
                      others: null,
                    },
                  };
                } else if (params.request_type === "view") {
                  updatedStates = {
                    ...this.state,
                    view: {
                      ...this.state.view,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      status: received.data.payload.outcome,
                    },
                  };
                }
                updatedStates = {
                  ...updatedStates,
                  render: {
                    loaded: true,
                    content_type: params.request_type,
                  },
                };

                this.setState(updatedStates);
              } else {
                this.messageHandler("error", received.data.message);
              }
            } else {
              this.messageHandler(
                "error",
                "Unexpected error, contact site administrator if persist."
              );
            }
          })
          .catch((error) => {
            this.toggleSpinner(false, "null");

            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              console.log(error);
              this.messageHandler(
                "error",
                "An Unexpected error has occurred, please contact site admin if persists."
              );
            }
          });
      } else {
        this.toggleSpinner(false, "null");

        this.messageHandler(
          "error",
          "Invalid parameters where parameters are expected"
        );
      }
    } else {
      this.toggleSpinner(false, "null");

      this.messageHandler("expired", "Session expired, please login again");
    }

    this.setState({
      ...this.state,
      render: {
        ...this.state.render,
        loaded: false,
      },
    });
  }

  posthandler(post_type, post_id, post_mode) {
    this.toggleSpinner(true, "null");

    let accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "post/users/" + post_type,
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: post_mode,
          request_meta: {
            grant_id: this.props.grant_infor.grant_id,
            user_id: post_id,
          },
        },
      })
        .then((received) => {
          this.toggleSpinner(false, "null");

          if (received.status === 200) {
            if (received.data.status) {
              this.loadpageHandler("table", null);
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler(
              "error",
              "Unexpected error occurred, contact site admin if persist"
            );
          }
        })
        .catch((error) => {
          this.toggleSpinner(false, "null");

          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    } else {
      this.toggleSpinner(false, "null");
      this.messageHandler("expired", "Session expired. Please log in again");
    }
  }

  viewHandler(event, submission_id) {
    this.loadpageHandler("view", submission_id);
  }

  deleteHandler(event, submission_id) {
    this.posthandler("delete", submission_id, null);
  }

  createHandler(event) {
    let updatedStates = {
      ...this.state,
      render: {
        loaded: true,
        content_type: "create",
        user_type: this.props.grant_infor.user_type,
      },
    };
    this.setState(updatedStates);
  }

  formHandler() {
    this.loadpageHandler("table", null);
  }

  selectHandler(event, submission_id) {
    if (event.target.value === "edit") {
      // both super admin and cluster admin
      this.loadpageHandler("form", submission_id);
    } else if (event.target.value === "delete") {
      let response = window.confirm(
        "Are you sure if this user is to be deleted?"
      );
      if (response) {
        this.posthandler("table", submission_id, "delete"); // au admin only
      }
    } else if (event.target.value === "reset") {
      let response = window.confirm(
        "Are you sure if you want to reset this user's password"
      );
      if (response) {
        this.posthandler("table", submission_id, "reset"); // au admin only
      }
    }
  }

  componentDidMount() {
    this.loadpageHandler("table", null);
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  render() {
    let header = <b>Submissions</b>;
    let content = <p>Loading ...</p>;

    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // load display
    if (this.state.render.loaded) {
      if (this.state.render.content_type === "table") {
        const ordered_headers = this.sortByOrder(this.state.table.header);

        let num_columns = 0;
        const table_header = Object.entries(ordered_headers).map(
          ([key, entry], idx) => {
            num_columns++;
            return <th key={key}>{entry.name}</th>;
          }
        );

        let table_content = (
          <tr>
            <td colSpan={num_columns}>No users found ...</td>
          </tr>
        );
        if (this.state.table.content.length > 0) {
          table_content = this.state.table.content.map((element, idx) => {
            let action_item = <p>N/A</p>;
            let user_name = <p>N/A</p>;

            for (const key in ordered_headers) {
              // generate action button
              if (key === "action") {
                action_item = (
                  <>
                    <Form.Control
                      as="select"
                      value=""
                      onChange={(event) =>
                        this.selectHandler(event, element["user_id"])
                      }
                    >
                      <option value="">(select)</option>
                      <option value="edit">Edit</option>
                      <option value="reset">Reset</option>
                      <option value="delete">Delete</option>
                    </Form.Control>
                  </>
                );
              } else if (key === "user_name") {
                user_name = (
                  <span
                    className="btn-link"
                    onClick={(event) =>
                      this.viewHandler(event, element["user_id"])
                    }
                  >
                    {element["user_name"]}
                  </span>
                );
              }
            }

            const rowElement = Object.entries(ordered_headers).map(
              ([key, entry], row_idx) => {
                if (key === "action") {
                  return <td key={row_idx}>{action_item}</td>;
                } else if (key === "user_name") {
                  return <td key={row_idx}>{user_name}</td>;
                } else {
                  return (
                    <td key={row_idx}>
                      {FormMapper[element[key]]
                        ? FormMapper[element[key]]
                        : element[key]}
                    </td>
                  );
                }
              }
            );

            return <tr key={idx}>{rowElement}</tr>;
          });
        }

        header = (
          <>
            <Row>
              <Col sm={10}>
                Create a new user? Click the button on the right.
              </Col>
              <Col sm={2}>
                <Button
                  size="sm"
                  variant="success"
                  onClick={(event) => this.createHandler(event)}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </>
        );

        content = (
          <>
            <Table striped bordered responsive>
              <thead>
                <tr>{table_header}</tr>
              </thead>
              <tbody>{table_content}</tbody>
            </Table>
          </>
        );
      } else if (this.state.render.content_type === "create") {
        header = <b>Create a new User</b>;
        content = (
          <UserCreate
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
          />
        );
      } else if (this.state.render.content_type === "form") {
        header = <b>Edit User with Id: {this.state.form.content["user_id"]}</b>;
        content = (
          <UserForm
            sub_infor={this.state.form}
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
          />
        );
      } else if (this.state.render.content_type === "view") {
        header = <b>User Summary</b>;
        content = (
          <UserView
            formHandler={this.formHandler}
            downloadHandler={this.downloadHandler}
            grant_infor={this.props.grant_infor}
            sub_infor={this.state.view}
          />
        );
      }
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>
              {this.state.spinner.toggle ? (
                <>
                  <Spinner animation="border" size="sm" /> Loading, please do
                  not refresh your page{" "}
                </>
              ) : null}
              {content}
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default Users;
