import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { customStyle } from "../../../../hoc/CustomStyle";

const FileInput = (props) => {
  /*
        props architecture:
            choices -> []
    
    */

  let secondaryContent = null;
  if (!props.loaded) {
    secondaryContent = <Spinner animation="border" variant="primary" />;
  } else {
    if (props.value === "False") {
      secondaryContent = <p>No file in our record</p>;
    } else {
      if ((props.touched && props.valid) || !props.touched) {
        secondaryContent = (
          <Button variant="warning" onClick={props.downloadhandler}>
            Download Existing
          </Button>
        );
      } else {
        secondaryContent = <p>No file in our record</p>;
      }
    }
  }

  let componentContent = (
    <>
      <Row>
        <Col sm={3}>
          {secondaryContent}
          {props.touched ? (
            <p
              style={
                props.valid
                  ? customStyle.successMessage
                  : customStyle.errorMessage
              }
            >
              {props.feedback}
            </p>
          ) : null}
        </Col>
        <Col sm={6} style={customStyle.bordered}>
          Upload/Reupload:
          <Form.Control type="file" onChange={props.uploadhandler} />
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={3}>
          <b>{props.label}</b>
          <p style={customStyle.subText}>{props.sublabel}</p>
        </Form.Label>
        <Col sm={9}>{componentContent}</Col>
      </Form.Group>
    </>
  );
};

export default FileInput;
