import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

// bootstrap reactjs
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// custom components
import Input from "../../../../components/UI/Input/Input";
import DynamicInput from "../../../../components/UI/Input/DynamicInput/DynamicInput";

// styling
import { customStyle } from "../../../../hoc/CustomStyle";
import { formValidity } from "../../../../hoc/Util";
import { user_init_admin } from "../../../../hoc/FormConfig";

import cloneDeep from "lodash/cloneDeep";

class SubmissionForm extends Component {
  state = {
    render: {
      mode: "loaded", //loading
      mounted: false,
    },

    controls: user_init_admin,

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  componentDidMount() {
    let updatedControls = user_init_admin;

    for (let elementKey in this.props.sub_infor.content) {
      if (updatedControls.hasOwnProperty(elementKey)) {
        if (
          this.props.sub_infor.content[elementKey] === "" ||
          this.props.sub_infor.content[elementKey] === []
        ) {
          updatedControls[elementKey].touched = false;
        }

        if (updatedControls[elementKey].elementType === "input-checkboxes") {
          var splitted_items =
            this.props.sub_infor.content[elementKey].split(",");
          if (splitted_items) {
            // update the value
            splitted_items = splitted_items.filter(function (el) {
              return el !== "";
            });
            updatedControls[elementKey].value = splitted_items;

            // update validity
            if (updatedControls[elementKey].validation) {
              let validateResult = formValidity(
                updatedControls[elementKey].value,
                updatedControls[elementKey].validation
              );
              updatedControls[elementKey].valid = validateResult.isValid;
              updatedControls[elementKey].feedback.status = updatedControls[
                elementKey
              ].valid
                ? "valid"
                : "invalid";
              updatedControls[elementKey].feedback.message =
                validateResult.message;
              updatedControls[elementKey].touched = true;
            }
          }
        } else if (updatedControls[elementKey].elementType === "input-table") {
          // dynamic table handling
          let subElementKeys = [];
          for (let key in updatedControls[elementKey].elementConfig.header) {
            subElementKeys.push(key);
          }

          let rows = [];
          let length_of_content =
            this.props.sub_infor.content[elementKey].length;
          for (let idx = 0; idx < length_of_content; idx++) {
            rows.push(
              cloneDeep(updatedControls[elementKey].elementConfig.header)
            );
          }

          let overall_valid = true;
          for (let idx = 0; idx < rows.length; idx++) {
            for (const key of subElementKeys) {
              rows[idx][key].value =
                this.props.sub_infor.content[elementKey][idx][key];
              rows[idx][key].handler = (event, controlName, colKey, rowIdx) =>
                this.dynamicInputChangedHandler(
                  event,
                  controlName,
                  colKey,
                  rowIdx
                );

              if (this.props.sub_infor.content[elementKey][idx][key] !== "") {
                rows[idx][key].touched = true;

                if (updatedControls[elementKey].validation) {
                  let validateResult = formValidity(
                    rows[idx][key].value,
                    rows[idx][key].validation
                  );
                  rows[idx][key].valid = validateResult.isValid;
                  overall_valid = overall_valid && validateResult.isValid;
                }
              }
            }
            rows[idx].handler = (event, controlName, idx) =>
              this.dynamicInputDeleteHandler(event, controlName, idx);
          }

          updatedControls[elementKey].value = rows;
          updatedControls[elementKey].valid = overall_valid;
        } else {
          updatedControls[elementKey].value =
            this.props.sub_infor.content[elementKey];

          if (updatedControls[elementKey].value !== "") {
            let validateResult = formValidity(
              updatedControls[elementKey].value,
              updatedControls[elementKey].validation
            );
            updatedControls[elementKey].valid = validateResult.isValid;
            updatedControls[elementKey].touched = true;
          }
        }
      }
    }

    this.setState({
      ...this.state,
      render: {
        ...this.state.render,
        user_id: this.props.sub_infor.user_id,
        mounted: true,
      },
      controls: updatedControls,
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.setState({
      ...this.state,
      render: {
        mode: "loaded", //loading
        mounted: false,
      },
      controls: null,
    });
  }

  multicheckHandler(event, controlName) {
    let updatedControlState = this.state.controls[controlName];

    if (event.target.checked) {
      updatedControlState.value.push(event.target.value);
    } else {
      var copy = [...updatedControlState.value];
      var index = copy.indexOf(event.target.value);
      if (index !== -1) {
        copy.splice(index, 1);
        updatedControlState.value = copy;
      }
    }

    if (
      updatedControlState.value.length >
      updatedControlState.validation.maxChoices
    ) {
      updatedControlState.valid = false;
      updatedControlState.touched = true;
      updatedControlState.feedback.status = "invalid";
      updatedControlState.feedback.message =
        "Please only select up to 3 choices";
    } else if (
      updatedControlState.value.length <
      updatedControlState.validation.minChoices
    ) {
      updatedControlState.valid = false;
      updatedControlState.touched = true;
      updatedControlState.feedback.status = "invalid";
      updatedControlState.feedback.message =
        "Please select at least " +
        updatedControlState.validation.minChoices +
        " choice" +
        (updatedControlState.validation.minChoices === 1 ? "" : "s");
    } else {
      updatedControlState.valid = true;
      updatedControlState.touched = true;
      updatedControlState.feedback.status = "valid";
      updatedControlState.feedback.message = "looks good!";
    }

    const updatedControls = {
      ...this.state.controls,
      [controlName]: updatedControlState,
    };

    this.setState({ controls: updatedControls });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = formValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    let updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        feedback: {
          ...this.state.controls[controlName].feedback,
          message: validateOutput.message,
        },
        touched: true,
      },
    };

    if (controlName === "theme") {
      updatedControls.theme_topics.value = [];
    }

    this.setState({ controls: updatedControls });
  };

  dynamicInputAddHandler = (event, controlName) => {
    let subElementKeys = [];
    for (let key in this.state.controls[controlName].elementConfig.header) {
      subElementKeys.push(key);
    }

    let validated = true;
    var col = cloneDeep(this.state.controls[controlName].elementConfig.header);
    let rows = [...this.state.controls[controlName].value, col];
    for (var idx = 0; idx < rows.length; idx++) {
      for (const key of subElementKeys) {
        rows[idx][key].handler = (event, controlName, colKey, rowIdx) =>
          this.dynamicInputChangedHandler(event, controlName, colKey, rowIdx);
        validated = rows[idx][key].valid && validated;
      }

      rows[idx].handler = (event, controlName, idx) =>
        this.dynamicInputDeleteHandler(event, controlName, idx);
    }

    const updatedRowElements = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: rows,
        valid: validated,
      },
    };

    this.setState({ controls: updatedRowElements });
  };

  dynamicInputDeleteHandler = (event, controlName, rowIdx) => {
    var updatedValue = [...this.state.controls[controlName].value];
    if (rowIdx !== -1) {
      updatedValue.splice(rowIdx, 1);

      let validated = true;
      for (var idx = 0; idx < updatedValue.length; idx++) {
        for (let key in updatedValue[idx]) {
          if (key !== "handler")
            validated = updatedValue[idx][key].valid && validated;
        }
      }

      this.setState({
        controls: {
          ...this.state.controls,
          [controlName]: {
            ...this.state.controls[controlName],
            value: updatedValue,
            valid: validated,
          },
        },
      });
    }
  };

  dynamicInputChangedHandler = (event, controlName, colKey, rowIdx) => {
    let validateOutput = formValidity(
      event.target.value,
      this.state.controls[controlName].value[rowIdx][colKey].validation
    );

    let updatedSubStates = this.state.controls[controlName].value;

    updatedSubStates[rowIdx][colKey] = {
      ...updatedSubStates[rowIdx][colKey],
      value: event.target.value,
      valid: validateOutput.isValid,
      feedback: {
        type: validateOutput.isValid ? "valid" : "invalid",
        message: validateOutput.message,
      },
      touched: true,
    };

    let validate = true;
    for (var idx = 0; idx < updatedSubStates.length; idx++) {
      for (let key in updatedSubStates[idx]) {
        if (key !== "handler") {
          validate = updatedSubStates[idx][key].valid && validate;
        }
      }
    }

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: updatedSubStates,
        valid: validate,
        feedback: {
          ...this.state.controls[controlName].feedback,
          message: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({
      ...this.state,
      controls: updatedControls,
    });
  };

  submitHandler = (type) => {
    //event.preventDefault();

    let values = {};
    if (this.props.sub_infor.content.user_id) {
      values["user_id"] = this.props.sub_infor.content.user_id;
    }

    let validated = true;
    let debug_message = "";
    Object.entries(this.state.controls).forEach(([formKey, formEntry], idx) => {
      if (validated && !formEntry.valid) {
        validated = false;
        debug_message = formKey;
      }

      // load data
      if (formEntry.elementType === "input-table") {
        let formValue = [];
        for (var i = 0; i < formEntry.value.length; i++) {
          let rowData = {};
          for (let key in formEntry.value[i]) {
            if (key !== "handler") {
              rowData[key] = formEntry.value[i][key].value;
            }
          }
          formValue.push(rowData);
        }
        values[formKey] = formValue;
      } else if (formEntry.elementType === "input-checkboxes") {
        values[formKey] = formEntry.value.join(",");
      } else {
        values[formKey] = formEntry.value;
      }
    });

    if (type === "save") {
      validated = true;
    }

    if (validated) {
      let accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "post/users/form",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: type,
            request_meta: values,
          },
        })
          .then((received) => {
            if (received.status === 200) {
              if (received.data.status) {
                if (type !== "save") {
                  this.props.formHandler();
                } else {
                  this.setState({
                    ...this.state,
                    render: {
                      ...this.state.render,
                      mode: "loaded",
                    },
                  });
                }
              } else {
                this.setState({
                  ...this.state,
                  render: {
                    ...this.state.render,
                    mode: "loaded",
                  },
                  internalMsg: {
                    triggered: true,
                    type: "error",
                    content: received.data.message,
                  },
                });
              }
            } else {
              this.setState({
                ...this.state,
                render: {
                  ...this.state.render,
                  mode: "loaded",
                },
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "received state not 200",
                },
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.setState({
                ...this.state,
                render: {
                  ...this.state.render,
                  mode: "loaded",
                },
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "critical error: " + error,
                },
              });
            }
          });
      }

      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          mode: "loading",
        },
      });
    } else {
      let field = "";
      if (this.state.controls[debug_message].label.main) {
        field = this.state.controls[debug_message].label.main;
      } else {
        field = this.state.controls[debug_message].label;
      }

      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: "error",
          content: "Something is wrong with '" + field + "' field",
        },
      });
    }
  };

  submitWrapper(event, confirmMsg) {
    event.preventDefault();
    if (window.confirm(confirmMsg) === true) {
      this.submitHandler("submit");
    }
  }

  render() {
    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => {
      if (formElement.config.elementType === "input-table") {
        return (
          <>
            <DynamicInput
              key={formElement.id}
              element={formElement.config}
              controlName={formElement.id}
              onadd={(event) =>
                this.dynamicInputAddHandler(event, formElement.id)
              }
            />
          </>
        );
      } else {
        return (
          <Input
            key={formElement.id}
            label={formElement.config.label}
            value={formElement.config.value}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            feedback={formElement.config.feedback}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangedHandler(event, formElement.id)}
          />
        );
      }
    });

    let save_btn = (
      <Button variant="primary" onClick={() => this.submitHandler("save")}>
        Save
      </Button>
    );
    let foot_note = null;
    let submit_msg = null;

    if (this.state.render.mode === "loading") {
      save_btn = (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Saving ...
        </Button>
      );
    }

    let confirmMsg = "Please check all necessary fields before endorsement!";
    let submitEvent = (event) => this.submitWrapper(event, confirmMsg);
    if (this.props.grant_infor.user_type === "admin-au") {
      submitEvent = (event) => this.submitWrapper(event, confirmMsg);
    }

    return (
      <>
        <p style={customStyle.subForeWord}>
          ** Please save your application regularly by using the Save button
        </p>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>{" "}
        {save_btn}
        <div style={customStyle.topBuffer20}>
          <Form id="" onSubmit={submitEvent}>
            {this.state.render.mounted ? form : <p>Loading ...</p>}
            <Button
              variant="info"
              onClick={() => this.props.formHandler("back")}
            >
              Back
            </Button>{" "}
            {save_btn}
          </Form>
          {msg}
          <br></br>
          {foot_note}
          {submit_msg}
        </div>
      </>
    );
  }
}

export default SubmissionForm;
