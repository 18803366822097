import React, { Component } from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

//styling
import { customStyle } from "../../../../hoc/CustomStyle";
import { FormMapper } from "../../../../hoc/FormConfig";
import { RevMapper } from "../../../../hoc/FormConfig";

class ReviewView extends Component {
  state = {
    render: {
      mode: "loaded", //loading
      mounted: false,
    },

    data: null,

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    let headers = this.sortByOrder(this.props.sub_infor.header);

    let Components = Object.entries(headers).map(([key, element], idx) => {
      let title = element.name;
      let content = this.props.sub_infor.content[key];

      let render = null;

      if (element.type === "list") {
        let list_items = content.map((value, idx) => {
          return (
            <li key={idx}>{FormMapper[value] ? FormMapper[value] : value}</li>
          );
        });

        render = <ul>{list_items}</ul>;
      } else if (element.type === "upload") {
        render = content ? (
          <Button
            variant="primary"
            onClick={() =>
              this.props.downloadHandler(
                key,
                this.props.sub_infor.content["app_id"]
              )
            }
          >
            Download
          </Button>
        ) : (
          <p>N/A</p>
        );
      } else {
        if (FormMapper[content]) {
          render = FormMapper[content];
        } else {
          if (RevMapper[key]) {
            render = RevMapper[key][content];
          } else {
            render = content;
          }
        }
      }

      return (
        <>
          <tr>
            <th>{title}</th>
            <td>{render}</td>
          </tr>
        </>
      );
    });

    let outcome_msg = null;
    if (this.props.sub_infor.status === "rejected") {
      outcome_msg = (
        <p style={customStyle.rejectedMsg}>
          {FormMapper[this.props.sub_infor.status]}
        </p>
      );
    } else if (this.props.sub_infor.status === "awarded") {
      outcome_msg = (
        <p style={customStyle.acceptedMsg}>
          {FormMapper[this.props.sub_infor.status]}
        </p>
      );
    } else {
      outcome_msg = (
        <p style={customStyle.inforMsg}>
          {FormMapper[this.props.sub_infor.status]}
        </p>
      );
    }

    let application_button = null;

    if (
      this.props.grant_infor.user_type === "admin-super" ||
      this.props.grant_infor.user_type === "reviewer" ||
      this.props.grant_infor.user_type === "reviewer-final"
    ) {
      application_button = (
        <Button
          variant="info"
          onClick={() =>
            this.props.downloadHandler(
              "review",
              this.props.sub_infor.content["app_id"],
              this.props.sub_infor.reviewer
            )
          }
        >
          Download review form
        </Button>
      );
    }

    let delete_button = null;

    if (this.props.grant_infor.user_type === "admin-super") {
      delete_button = (
        <Button
          variant="warning"
          onClick={() =>
            this.props.downloadHandler(
              "delete",
              this.props.sub_infor.content["app_id"],
              this.props.sub_infor.reviewer
            )
          }
        >
          Delete review
        </Button>
      );
    }

    return (
      <>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>{" "}
        {delete_button}
        <div style={customStyle.topBuffer20}>
          <Table striped bordered hover size="sm">
            <tbody>
              <tr>
                <th>Status</th>
                <td>{outcome_msg}</td>
              </tr>
              {Components}
            </tbody>
          </Table>
        </div>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>{" "}
        {application_button}
      </>
    );
  }
}

export default ReviewView;
