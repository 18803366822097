import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import Auth from "./containers/Auth/Auth";
import Application from "./containers/Auth/Application";
import ForgetLogin from "./containers/Auth/ForgetLogin";
import Recovery from "./containers/Auth/Recovery";

import Dashboard from "./containers/Dashboard/Dashboard";

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path="/admin" component={Auth} />
            <Route path="/" exact component={Application} />
            <Route path="/forgetlogin" component={ForgetLogin} />
            <Route path="/recovery" component={Recovery} />
            <Route path="/dashboard" component={Dashboard} />
            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
