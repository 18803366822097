import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

const ModalElement = (props) => {
  let title_element = <Modal.Title>{props.message.title.value}</Modal.Title>;
  let body_element = null;
  let actionButton = null;

  switch (props.displayMode) {
    case "display":
      body_element = Object.entries(props.message).map(
        ([key, element], idx) => {
          let displayElement = null;
          if (element.type === "textarea") {
            displayElement = (
              <Form.Control
                as="textarea"
                rows="10"
                readOnly
                defaultValue={element.value}
              />
            );
          } else if (element.type === "bullet") {
            let listItem = element.value.map((value, idx) => (
              <ListGroup.Item key={idx}>{value}</ListGroup.Item>
            ));
            displayElement = <ListGroup>{listItem}</ListGroup>;
          } else if (element.type === "file") {
            displayElement =
              element.value === null ? (
                <p>N/A</p>
              ) : (
                <Button variant="primary" onClick={element.value}>
                  Download
                </Button>
              );
          } else {
            displayElement = (
              <Form.Control readOnly defaultValue={element.value} />
            );
          }

          return (
            <Form.Group as={Row} key={key.toString() + idx.toString()}>
              <Col md={3}>
                <Form.Label column>{element.header}</Form.Label>
              </Col>
              <Col md={9}>{displayElement}</Col>
            </Form.Group>
          );
        }
      );
      break;

    case "action":
      body_element = <p>{props.message.content.value}</p>;
      actionButton = (
        <Button variant="success" onClick={() => props.action.handler}>
          {props.action.message}
        </Button>
      );
      break;

    default:
      body_element = (
        <p>Are you sure you want to perform the following action?</p>
      );
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Kinda hacky... */}
      <span className="btn-link" onClick={handleShow}>
        {props.message.title.value}
      </span>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <b>{title_element}</b>
        </Modal.Header>
        <Modal.Body>{body_element}</Modal.Body>
        <Modal.Footer>
          {actionButton}
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalElement;
