import React from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { customStyle } from "../../../../hoc/CustomStyle";

const DynamicInput = (props) => {
  /*
        args: props.element
    */

  let componentContent = <p>Coming Soon</p>;

  const rowElements = props.element.value;

  // receive: props.header, props.content (array of objects)
  let table_element = rowElements.map((rowElement, rowIdx) => {
    let render_result = Object.entries(rowElement).map(
      ([colKey, colEntry], colIdx) => {
        if (colKey !== "handler") {
          //let config_type = "input";
          //if (
          //  colEntry.config.type === "select" ||
          //  colEntry.config.type === "textarea"
          //)
          //  config_type = colEntry.config.type;

          if (colEntry.config.type === "select") {
            return (
              <Form.Group key={colKey + colIdx.toString()} as={Row}>
                <Form.Label column sm={3}>
                  <p style={customStyle.subHeader}>{colEntry.label}</p>
                  <p style={customStyle.subText}>{colEntry.sublabel}</p>
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as={colEntry.config.type}
                    value={colEntry.value}
                    isValid={colEntry.valid && colEntry.touched ? true : false}
                    isInvalid={
                      !colEntry.valid && colEntry.touched ? true : false
                    }
                    required={colEntry.config.required}
                    onChange={(event) =>
                      colEntry.handler(event, props.controlName, colKey, rowIdx)
                    }
                  >
                    {colEntry.config.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type={colEntry.feedback.type}>
                    {colEntry.feedback.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            );
          } else {
            return (
              <Form.Group key={colKey + colIdx.toString()} as={Row}>
                <Form.Label column sm={3}>
                  <p style={customStyle.subHeader}>{colEntry.label}</p>
                  <p style={customStyle.subText}>{colEntry.sublabel}</p>
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    {...colEntry.config}
                    value={colEntry.value}
                    isValid={colEntry.valid && colEntry.touched ? true : false}
                    isInvalid={
                      !colEntry.valid && colEntry.touched ? true : false
                    }
                    required={colEntry.config.required}
                    onChange={(event) =>
                      colEntry.handler(event, props.controlName, colKey, rowIdx)
                    }
                  />
                  <Form.Control.Feedback type={colEntry.feedback.type}>
                    {colEntry.feedback.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            );
          }
        }
        return <></>;
      }
    );

    return (
      <tr key={"element" + rowIdx.toString()}>
        <td>{render_result}</td>
        <td style={customStyle.center}>
          <Button
            variant="outline-danger"
            size="sm"
            onClick={(event) =>
              rowElement.handler(event, props.controlName, rowIdx)
            }
          >
            (-)
          </Button>
        </td>
      </tr>
    );
  });

  componentContent = (
    <>
      <Table striped bordered>
        <tbody>{table_element}</tbody>
        <tfoot>
          <tr>
            <td>Click on the button on the right to add rows</td>
            <th style={customStyle.center}>
              <Button variant="outline-success" size="sm" onClick={props.onadd}>
                (+)
              </Button>
            </th>
          </tr>
        </tfoot>
      </Table>
    </>
  );

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={3}>
          <b>{props.element.label.main}</b>
          <p style={customStyle.subText}>{props.element.label.sub}</p>
        </Form.Label>
        <Col sm={9}>{componentContent}</Col>
      </Form.Group>
    </>
  );
};

export default DynamicInput;
