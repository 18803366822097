import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { customStyle } from "../../../../hoc/CustomStyle";

const MultiCheckboxes = (props) => {
  const checkboxes = props.element.elementConfig.choices.map((entry, idx) => {
    let isDisabled = false;
    let isChecked = props.element.value.includes(entry.value);
    if (props.dependent !== null) {
      if (props.dependent === "none") {
        isDisabled = true;
      } else if (entry.value.split("-")[0] !== props.dependent) {
        isChecked = false;
        isDisabled = true;
      }
    }

    return (
      <div key={idx}>
        <label key={idx}>
          <input
            type="checkbox"
            value={entry.value}
            onClick={(event) => props.onchanged(event)}
            checked={isChecked}
            disabled={isDisabled}
          />{" "}
          <span>{entry.label}</span>
        </label>
      </div>
    );
  });

  let msg = null;
  if (props.element.touched) {
    if (props.element.valid) {
      msg = (
        <p style={customStyle.successMessage}>
          {props.element.feedback.message}
        </p>
      );
    } else {
      msg = (
        <p style={customStyle.errorMessage}>{props.element.feedback.message}</p>
      );
    }
  }

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={3}>
          <b>{props.element.label.main}</b>
          <p style={customStyle.subText}>{props.element.label.sub}</p>
        </Form.Label>
        <Col sm={9}>
          {checkboxes}
          {msg}
        </Col>
      </Form.Group>
    </>
  );
};

export default MultiCheckboxes;
