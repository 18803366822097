import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import NavigationItems from "../../components/Navigations/NavigationItems";
import Input from "../../components/UI/Input/Input";
import Aux from "../../hoc/Misc/Misc";
import axios from "axios";

import { checkValidity } from "../../hoc/Util";
import { customStyle } from "../../hoc/CustomStyle";
import { app_access_applicant } from "../../hoc/FormConfig";

import ApplicantForm from "./ApplicantForm/ApplicantForm";
import ApplicantView from "./ApplicantView/ApplicantView";

// inBuilt bootstrap style
const controlClasses = {
  frame: {
    col: {
      span: 8,
      offset: 2,
    },
  },
};

class Application extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "app_access", // app_access, app_form, app_view
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    view: {
      retrieve_id: null,
      header: null,
      content: null,
      status: null,
    },

    controls: app_access_applicant,

    loading: false,

    message: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.formHandler = this.formHandler.bind(this);
  }

  toggleLoading(status) {
    this.setState({ ...this.state, loading: status });
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  loadappHandler = (content_type) => {
    let accessToken = localStorage.getItem("app_access");

    if (accessToken) {
      this.toggleLoading(true);

      axios({
        url:
          process.env.REACT_APP_AXIOS_URL + "fetch/applicant/" + content_type,
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: "infor",
          retrieve_id: null,
        },
      })
        .then((received) => {
          this.toggleLoading(false);
          let _success = received.data.status;

          if (_success) {
            if (content_type === "form") {
              let updatedStates = {
                ...this.state,
                render: {
                  loaded: true,
                  content_type: "app_form", // app_access, app_form, app_view
                },
                form: {
                  header: received.data.payload.header,
                  content: received.data.payload.content,
                },
                message: {
                  triggered: true,
                  type: "success",
                  content: "Retrieve Application Success!",
                },
              };
              this.setState(updatedStates);
            } else {
              let updatedStates = {
                ...this.state,
                render: {
                  loaded: true,
                  content_type: "app_view", // app_access, app_form, app_view
                },
                view: {
                  ...this.state.view,
                  header: received.data.payload.header,
                  content: received.data.payload.content,
                  outcome: received.data.payload.outcome,
                },
                message: {
                  triggered: true,
                  type: "success",
                  content: "Retrieve Application Success!",
                },
              };
              this.setState(updatedStates);
            }
          } else {
            let updatedStates = {
              ...this.state,
              message: {
                triggered: true,
                type: "error",
                content:
                  "Fail to retrieve application, check your application id and access code",
              },
            };
            this.setState(updatedStates);
          }
        })
        .catch((error) => {
          this.toggleLoading(false);
        });
    } else {
      this.toggleLoading(false);
    }
  };

  retrieveHandler = (event) => {
    event.preventDefault();

    let validated = true;

    if (!this.state.controls.app_id.valid) {
      validated = false;
      //console.log("Application Id format is invalid");
    }

    if (!this.state.controls.access_code.valid) {
      validated = false;
      //console.log("Access Code format is invalid");
    }

    if (validated) {
      this.toggleLoading(true);
      axios
        .post(process.env.REACT_APP_AXIOS_URL + "fetch/applicant/retrieve", {
          app_id: this.state.controls.app_id.value,
          app_access: this.state.controls.access_code.value,
        })
        .then((received) => {
          this.toggleLoading(false);
          let _success = received.data.status;

          if (_success) {
            localStorage.setItem("app_access", received.data.payload.token);

            if (received.data.payload.app_status === "unfilled") {
              this.loadappHandler("form");
            } else {
              this.loadappHandler("view");
            }
          } else {
            let updatedStates = {
              ...this.state,
              message: {
                triggered: true,
                type: "error",
                content:
                  "Fail to retrieve application, check your application id and access code",
              },
            };
            this.setState(updatedStates);
          }
        })
        .catch((error) => {
          this.toggleLoading(false);
        });
    } else {
      this.toggleLoading(false);
    }
  };

  formHandler() {
    localStorage.clear("app_access");
    let updatedStates = {
      ...this.state,
      render: {
        loaded: true,
        content_type: "app_access", // app_access, app_form, app_view
      },
      form: {
        header: null,
        content: null,
      },
    };
    this.setState(updatedStates);
  }

  render() {
    // message handling
    let msg = null;
    if (this.state.message.triggered) {
      if (this.state.message.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>{this.state.message.content}</p>
        );
      }
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        feedback={formElement.config.feedback}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    // load display
    let header = null;
    let footer = "";
    let content = <p>Loading ...</p>;

    if (this.state.render.content_type === "app_access") {
      header = <b>Retrieve Application</b>;
      content = (
        <>
          <Form onSubmit={this.retrieveHandler}>
            {form}
            {this.state.loading ? (
              <Button variant="primary" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                  size="sm"
                />
                Retrieving...
              </Button>
            ) : (
              <Button type="submit">Retrieve</Button>
            )}
          </Form>
        </>
      );
      footer = (
        <p>
          Forgot your application number and access code? Check the email from
          your faculty administrator.
        </p>
      );
    } else if (this.state.render.content_type === "app_form") {
      header = <b>Applicant Form</b>;
      content = (
        <ApplicantForm
          formHandler={this.formHandler}
          sub_infor={this.state.form}
        />
      );
    } else if (this.state.render.content_type === "app_view") {
      header = <b>Application Summary</b>;
      content = (
        <ApplicantView
          formHandler={this.formHandler}
          sub_infor={this.state.view}
        />
      );
    }

    return (
      <Aux>
        <NavigationItems login={false} />
        <Container>
          <Row>
            <Col
              md={
                this.state.render.content_type === "app_access"
                  ? controlClasses.frame.col
                  : null
              }
            >
              {msg}
              <Card style={customStyle.topBuffer30}>
                <Card.Header>
                  <b>{header}</b>
                </Card.Header>
                <Card.Body>{content}</Card.Body>
                <Card.Footer className="text-muted">{footer}</Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Aux>
    );
  }
}

export default Application;
