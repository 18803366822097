import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

// bootstrap reactjs
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// custom components
import Input from "../../../../components/UI/Input/Input";

// styling
import { customStyle } from "../../../../hoc/CustomStyle";
import { formValidity } from "../../../../hoc/Util";
import { app_init_admin } from "../../../../hoc/FormConfig";

class SubmissionCreate extends Component {
  state = {
    render: {
      mode: "loaded", //loading
      mounted: false,
    },

    controls: app_init_admin,

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = formValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    let updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        feedback: {
          ...this.state.controls[controlName].feedback,
          message: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  submitHandler = (type) => {
    let values = {};
    values["grant_id"] = this.props.grant_infor.grant_id;

    let validated = true;
    let debug_message = "";
    Object.entries(this.state.controls).forEach(([formKey, formEntry], idx) => {
      if (validated && !formEntry.valid) {
        validated = false;
        debug_message = formKey;
      }
      values[formKey] = formEntry.value;
    });

    if (validated) {
      let accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "post/applications/table",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: type,
            request_meta: values,
          },
        })
          .then((received) => {
            if (received.status === 200) {
              if (received.data.status) {
                this.props.formHandler();
              } else {
                this.setState({
                  ...this.state,
                  render: {
                    ...this.state.render,
                    mode: "loaded",
                  },
                  internalMsg: {
                    triggered: true,
                    type: "error",
                    content: received.data.message,
                  },
                });
              }
            } else {
              this.setState({
                ...this.state,
                render: {
                  ...this.state.render,
                  mode: "loaded",
                },
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "received state not 200",
                },
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.setState({
                ...this.state,
                render: {
                  ...this.state.render,
                  mode: "loaded",
                },
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "critical error: " + error,
                },
              });
            }
          });
      }

      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          mode: "loading",
        },
      });
    } else {
      // Show some hints that we failed the validation process.
      let field = "";
      if (this.state.controls[debug_message].label.main) {
        field = this.state.controls[debug_message].label.main;
      } else {
        field = this.state.controls[debug_message].label;
      }

      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: "error",
          content: "Something is wrong with '" + field + "' field",
        },
      });
    }
  };

  submitWrapper(event) {
    event.preventDefault();
    this.submitHandler("create");
  }

  render() {
    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => {
      return (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          value={formElement.config.value}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          feedback={formElement.config.feedback}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={(event) => this.inputChangedHandler(event, formElement.id)}
        />
      );
    });

    let submit_btn = (
      <Button variant="success" type="submit">
        Create
      </Button>
    );
    let submit_msg = null;
    if (this.state.render.mode === "loading") {
      submit_btn = (
        <Button variant="success" disabled>
          Submit
        </Button>
      );
      submit_msg = (
        <>
          <p style={customStyle.subConfirm}>
            <Spinner animation="border" size="sm" />
            Please wait, you will be automatically redirected...
          </p>
        </>
      );
    }

    return (
      <>
        <p style={customStyle.subForeWord}>
          Create an application by entering the following details
        </p>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>{" "}
        <div style={customStyle.topBuffer20}>
          <Form onSubmit={(event) => this.submitWrapper(event)}>
            {form}
            {submit_btn}
          </Form>
          {msg}
          <br></br>
          {submit_msg}
        </div>
      </>
    );
  }
}

export default SubmissionCreate;
